import * as React from 'react'
import { graphql } from 'gatsby'
import { Helmet } from 'react-helmet'

// IMAGES

// COMPONENTS
import Header from '../components/header'
import Footer from '../components/footer'

//STYLES
import '../styles/contact.scss'

const Contact = ({ data }) => {
  const md = data.allMarkdownRemark.edges[0].node.frontmatter
  return (
    <div id="contact">
      <Helmet>
        <title>Kontakt</title>
        <meta
          name="description"
          content="Har du några funderingar eller liknande? Kontakta oss genom denna länken!"
        ></meta>
      </Helmet>
      <div className="background-image" alt="background image">
        <Header />
        <h1 className="header-title">{md.title}</h1>
      </div>
      <div className="contact-section">
        <div>
          <h2 className="section-header">{md.questionsh2}</h2>
          <p className="contact-text">
            Klicka <a href="https://cloudenablers.se/contact">här</a> för att
            komma till CloudEnablers kontaktsida, företaget som utvecklat iHug.
          </p>
        </div>
      </div>
      <Footer />
    </div>
  )
}

export const query = graphql`
  {
    allMarkdownRemark(filter: { frontmatter: { key: { eq: "contact" } } }) {
      edges {
        node {
          frontmatter {
            title
            questionsh2
          }
        }
      }
    }
  }
`

export default Contact
